<template>
    <div class="view pa24 wordAdmin">
        <div class="selectCompany flex-a-c hover_pointer" @click="showCompanyList"><i
                class="el-icon-office-building fs12 mr5"></i> <span class="fs13">{{ checkedCompany.companyName }}</span> <i
                class="el-icon-caret-bottom  fs12 ml5"></i></div>
        <el-tabs v-model="status" @tab-click="searchFun">
            <el-tab-pane label="模板文档" name="all"></el-tab-pane>
            <!-- <el-tab-pane label="生产文档" name="3"></el-tab-pane> -->
            <el-tab-pane label="创建成功" name="1"></el-tab-pane>
            <el-tab-pane label="创建失败" name="2"></el-tab-pane>

        </el-tabs>
        <div class="d-flex mt20">
            <div>
                <el-input class="w400 mr10" placeholder="请输入文档名称关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
            </div>
            <div style="margin-left: auto" v-if="status != 3">
                <el-button type="primary" @click="showAddWordFun" :disabled="!checkedCompany.pcId"
                    class="wordBtn">生成Word文档</el-button>
            </div>
        </div>
        <div v-show="status != 3">
            <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
                <template v-slot:table>
                    <el-table-column type="index" align="center" label="序号" />
                    <el-table-column prop="projectName" align="center" label="项目名称" show-overflow-tooltip />
                    <el-table-column prop="modelName" align="center" label="模板名称" show-overflow-tooltip />
                    <el-table-column prop="userName" align="center" label="填写用户" show-overflow-tooltip width="100" />
                    <el-table-column prop="createTime" align="center" label="填写时间" show-overflow-tooltip width="180" />
                    <el-table-column prop="remark" align="center" label="描述" show-overflow-tooltip />
                    <el-table-column prop="status" align="center" label="状态" show-overflow-tooltip width="100">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.status == 1">创建成功</el-tag>
                            <el-tag v-if="scope.row.status == 2" type="danger">创建失败</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fileUrl" align="center" label="下载" show-overflow-tooltip width="50">
                        <template slot-scope="scope">
                            <a :href="scope.row.fileUrl" class="cblue" target="_blank" title="文档下载"
                                :download="scope.row.projectName" style="color: #3a78ef;"><i class="icondoc iconYQH"></i>
                            </a>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" @click="showSetModel(scope.row)">编辑</el-button>
                            <!-- <el-button type="text" @click="showModelContent(scope.row)">查看内容</el-button> -->
                            <el-button style="color:#F56C6C" type="text" @click="delWordData(scope.row)">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </template>
            </commonTable>
        </div>
        <div v-show="status == 3">
            <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
                <template v-slot:table>
                    <el-table-column type="index" align="center" label="序号" />
                    <el-table-column prop="projectName" align="center" label="项目名称" show-overflow-tooltip />
                    <el-table-column prop="modelName" align="center" label="模板名称" show-overflow-tooltip width="150" />
                    <el-table-column prop="userName" align="center" label="填写用户" show-overflow-tooltip width="150" />
                    <el-table-column prop="createTime" align="center" label="填写时间" show-overflow-tooltip width="180" />
                    <el-table-column align="center" label="下载" width="50">
                        <template slot-scope="scope">
                            <div class="cblue hover_pointer w30 h30" title="文档下载" @click="downloadWrod(scope.row)"
                                style="color: #3a78ef;">
                                <i class="icondoc iconYQH"></i>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
                            <!-- <el-button type="text" @click="showModelContent(scope.row)">查看内容</el-button> -->
                            <el-button style="color:#F56C6C" type="text" @click="delNormalWordData(scope.row)">删除
                            </el-button>
                        </template>
                    </el-table-column>

                </template>
            </commonTable>
        </div>
        <el-dialog :close-on-click-modal="false" @close="closeCreateWord()" :modal-append-to-body="false" title="生成文档"
            width="900px" :visible.sync="showAddWord">
            <div class="setWordView" v-loading="loadingModel">
                选择模板和研发项目填写内容，生成文档
                <div class=" e6a23c">
                    选择研发项目或参考内容会覆盖掉之前填写的内容
                </div>
                <div class="selectCompany mt10 mb20 flex-a-c"><i class="el-icon-office-building fs12 mr5"></i>{{
                    checkedCompany.companyName }}</div>
                <el-form :model="formData" :rules="formRules" ref="formData" label-width="90px">
                    <el-form-item label="研发项目" prop="pdId">
                        <el-button type="primary" @click="showProjectTable">
                            {{ checkedProjectName }}
                        </el-button>
                    </el-form-item>
                    <el-form-item label="word模板" prop="modelId">
                        <el-button type="primary" @click="showModelTable" class="wordBtn">
                            {{ checkedModelName }}
                        </el-button>
                    </el-form-item>
                </el-form>
                <el-divider content-position="left">描述</el-divider>
                <el-input class="pl20 pr20 w100p" type="textarea" v-model="remark" :rows="5"></el-input>
                <div class="mt20 ml20">
                    <el-button type="primary" @click="showWordTable">
                        生成参考内容
                    </el-button>
                </div>
                <el-backtop target=".setWordView"></el-backtop>
                <!-- <el-collapse class="mt20" v-model="activeNames" v-if="modelContent.html">
                        <el-collapse-item title="文档格式" name="1">
                            <div class="showModelContent" :class="formType == 'add' ? 'curPo' : ''"
                                v-html="modelContent.html" v-if="showAddWord"></div>
                        </el-collapse-item>
                    </el-collapse> -->
                <div class="mt40 flex-c-c">
                    <el-button size="small" @click="closeCreateWord()">取消</el-button>
                    <el-button size="small" type="primary" @click="showModelFun()" class="wordBtn">生成word文档</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="查看文档内容" :visible.sync="showModel"
            :destroy-on-close="true" width="900px" center>
            <div v-loading="loadingModel">
                <el-divider content-position="left">描述</el-divider>
                <el-input class="pl20 pr20 w100p" type="textarea" v-model="remark" readonly :rows="5"></el-input>
                <el-divider></el-divider>
                <div class="showModelContent" v-html="modelContent.html" v-if="showModel"></div>
                <div class="mt40 flex-c-c" v-if="modelContent.html">
                    <el-button size="small" @click="showModel = false">取消</el-button>
                </div>
            </div>
        </el-dialog> -->
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="文档内容" @close="hideCreateWord()"
            :visible.sync="showModel" :destroy-on-close="true" width="900px" class="createWord" center>
            <div>
                <!-- <el-button size="small" type="primary" @click="addWord()" :loading="addWordDataLoading"
                    class="wordBtn">生成word文档</el-button> -->
                <div class="uploadBtn flex-c-c" @click="addWord()" title="点击上传Word模板">
                    <i class="el-icon-loading" v-if="addWordDataLoading"></i>
                    <i class="el-icon-upload" v-else></i>
                </div>
                <div class="closeBtn flex-c-c" @click="showModel = false" title="取消">
                    <i class="el-icon-close"></i>
                </div>
                <div class="showModelContent curPo" v-html="modelContent.html" v-if="showAddWord"></div>
                <el-card class="box-card mt20 ml20 mr20" v-if="modelContent.html">
                    <div slot="header" class="flex-a-b-c">
                        <div>
                            <div class="fs8">Word文档覆盖图片</div>
                            <div class="fs6 c999">图片会根据标识覆盖上去，不会影响到格式的变化。不填标识，图片会默认添加到底部</div>
                        </div>
                        <div>
                            <el-upload multiple class="uploadWordImage" :auto-upload="false" action="#"
                                :show-file-list="false" :on-change="addWordImage" accept=".jpg,.png"
                                :file-list="wordFileImage">
                                <el-button size="small" type="text">上传图片</el-button>
                            </el-upload>
                        </div>
                    </div>
                    <el-row v-if="wordImageData.length > 0">
                        <el-col v-for="(row, index) of wordImageData" :key="index" :lg="18">
                            <div class="flex-c-c wordImageData">
                                <i class="el-icon-close" @click="delWordImage(index)"></i>
                                <div>
                                    <el-image class="wordImage" :preview-src-list="[row.url]"
                                        style="width: 100px; height: 100px" :src="row.url" fit="cover"></el-image>
                                </div>
                                <div class="ml10">
                                    <el-input placeholder="覆盖标识" v-model="row.tabText"></el-input>
                                    <div class="flex-c-c">
                                        <el-input placeholder="宽度" type="number" class="suffixInput" v-model="row.width">
                                            <template slot="append">PX</template>
                                        </el-input>
                                        <el-input placeholder="高度" type="number" class="suffixInput" v-model="row.height">
                                            <template slot="append">PX</template>
                                        </el-input>
                                    </div>
                                    <div class="flex-c-c">
                                        <el-input placeholder="左偏移" type="number" class="suffixInput"
                                            v-model="row.leftOffset">
                                            <template slot="append">PX</template>
                                        </el-input>
                                        <el-input placeholder="上偏移" type="number" class="suffixInput"
                                            v-model="row.topOffset">
                                            <template slot="append">PX</template>
                                        </el-input>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="textc" v-else>
                        <p class="fs9">匹配标识覆盖图片</p>
                    </div>
                </el-card>
                <!-- <div class="mt40 flex-c-c" v-if="modelContent.html">
                    <el-button size="small" @click="showModel = false">取消</el-button>
                    <el-button size="small" type="primary" @click="addWord()" :loading="addWordDataLoading"
                        class="wordBtn">生成word文档</el-button>
                </div> -->
            </div>
        </el-dialog>
        <!-- 项目列表 -->
        <project-table ref="projectTable" @confirmFun="selectProject"></project-table>
        <!-- 文档列表 -->
        <word-table ref="wordTable" @confirmFun="selectWord"></word-table>
        <!-- 模板列表 -->
        <model-table ref="modelTable" @confirmFun="selectModel"></model-table>
        <!-- 研发企业列表 -->
        <select-company-list ref="selectCompanyList" @confirmFun="selectCompany"></select-company-list>
        <!-- GPT -->
        <!-- <gpt-model ref="gptModel" v-show="status != 3" :GPTMessage="message" @caleChange="caleChange"
            @clickAnswer="clickAnswer" :gptTitle="gptTitle" :commonUseMessage="commonUseMessage"></gpt-model> -->
        <project-develop v-loading="modelLoading" v-show="status == 3" ref="projectDevelop" :projectData="detailsProject"
            :modelData="detailsModel" setType="update"></project-develop>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
// import gptModel from "@/components/common/gptModel";
import projectTable from "@/pages/projectAdmin/components/projectTable";
import modelTable from "@/pages/projectAdmin/components/modelTable";
import wordTable from "@/pages/projectAdmin/components/wordTable";
import selectCompanyList from "@/pages/projectAdmin/components/selectCompanyList";
import projectDevelop from "@/pages/projectAdmin/components/projectDevelop";
import {
    getWordDataList, delWordData,
    getWordModelList, writeAndDownload,
    getNormalWordDataList, setProjectNormalWord,
    getOneNormalWordData, delNormalWordData
} from "@/api/projectAdmin";
import { copyText } from "@/utils";
let base64 = require('js-base64').Base64
export default {
    name: "groupingTable",
    components: {
        commonTable,
        projectTable,
        modelTable,
        wordTable,
        selectCompanyList,
        // gptModel,
        projectDevelop
    },
    data() {
        return {
            userInfo: this.$store.state.loginRoot.userInfo,
            wordFileImage: [],
            uploadingUrl: this.$store.state.uploadingUrl,
            // activeNames: ["1"],
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            loadingModel: false,
            tableData: [],
            searchData: { name: "" },
            //导入模板配置
            showAddWord: false,
            modelContent: {
                html: "",
                normal: [],
                list: []
            },
            modelDataTable: [
                { title: "-" },
                { title: "-" },
                { title: "-" }
            ],
            //生成文档配置
            checkedProjectName: "选择研发项目",
            checkedProject: {},
            checkedModelName: "选择文档模板",
            checkedModel: {},
            checkedWord: {},
            remark: "",
            showContent: false,
            status: "all",
            tableTrData: {},
            param: {},
            formData: {
                modelId: "",
                pdId: ""
            },
            updateId: 0,
            formRules: {
                modelId: [{
                    required: true, message: '请选择模板',
                }],
                pdId: [{
                    required: true, message: '请选择研发项目',
                }],
            },
            addWordDataLoading: false,
            checkedCompany: {},
            wordImageData: [],
            topic: {},
            gptTitle: "",
            commonUseMessage: [
                '如何增加申报项目成功率',
                '申报项目该准备什么材料',
                '项目申报成功后资金要多久才到账',
            ],
            message: "",
            detailsModel: {},
            detailsProject: {},
            modelLoading: false
        };
    },
    computed: {
        getRowNumber() {
            return (str) => {
                let rows = Math.ceil(String(str).length / 100)
                return rows ? rows : 1;
            }
        }
    },
    mounted() {

        let checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
        if (!checkedCompany) {
            this.showCompanyList();
        }
        this.checkedCompany = checkedCompany ? checkedCompany : { companyName: "请选择研发企业" };
        this.getList();
        this.createeWordInfoClick();
    },
    methods: {
        /**@method 下载word */
        downloadWrod(data) {
            let params = {
                dataId: data.dataId,
                isDownload: true,
                modelType: data.modelType
            };
            setProjectNormalWord(params).then(res => {
                if (res.code === 200) {
                    let link = document.createElement('a');
                    link.href = this.$store.state.apiUrl + '/crmPc/project/downloadWordFile?fileName=' + res.data;
                    link.click();

                } else {
                    this.$message.error(res.message)
                }
            })
        },
        /**@method 选择要填写的题目 */
        createeWordInfoClick() {
            let that = this;
            $(".wordAdmin").on("click", ".isInput", function () {
                if ($(this).children("textarea").length == 0) {
                    let text = $(this).data("title");
                    that.topic.text = text;
                    that.gptTitle = '题目： ' + text;
                    // that.$refs.gptModel.showHideGptModel(true);
                    that.topic.classText = "isInput";
                    that.message = text;
                }
            })
            $(".wordAdmin").on("dblclick", ".isInput", function () {
                if ($(this).children("textarea").length == 0) {
                    $(this).html(`<textarea class="wordTextarea">${$(this).html().replace(/<br>+/ig, "\n")}</textarea>`);
                    // $(this).children("textarea").focus()
                    $(this).children("textarea").select();
                    that.$nextTick(function () {
                        let h = $(".wordTextarea")[0].scrollHeight;
                        $(".wordTextarea").css("height", (h + 5) + "px");
                        $(".wordTextarea").css("width", $(".wordTextarea").parent().scrollWidth);
                    })
                }
            })
            $(".wordAdmin").on("blur", ".isInput textarea.wordTextarea", function () {
                $(this).parent().html($(this).val() ? $(this).val().replace(/\n+/ig, "<br>") : $(this).parent().attr("name"));
            })
            $(".wordAdmin").on("click", ".modelTable .isList", function () {
                if ($(this).children("textarea").length == 0) {
                    let text = $(this).data("title");
                    that.topic.text = text;
                    that.gptTitle = '题目： ' + text;
                    // that.$refs.gptModel.showHideGptModel(true);
                    that.topic.classText = "isList";
                    that.message = text;
                }
            })
            $(".wordAdmin").on("dblclick", ".modelTable .isList", function () {
                if ($(this).children("textarea").length == 0) {
                    $(this).html(`<textarea class="wordTextarea">${$(this).html().replace(/<br>+/ig, "\n")}</textarea>`);
                    $(this).children("textarea").select();
                    that.$nextTick(function () {
                        let h = $(".wordTextarea")[0].scrollHeight;
                        $(".wordTextarea").css("height", (h + 5) + "px");
                        // $(".wordTextarea").css("width",$(this)[0].scrollWidth);
                    })
                }
            })
            $(".wordAdmin").on("blur", ".modelTable textarea.wordTextarea", function () {
                $(this).parent().html($(this).val() ? $(this).val().replace(/\n+/ig, "<br>") : $(this).parent().attr("name"));
            })
            $(".wordAdmin").on("click", ".rowTop", function () {
                let myHtml = $(this).parents("tr").html();
                let bottomHtml = $(this).parents("tr").prev().html();
                $(this).parents("tr").prev().html(myHtml);
                $(this).parents("tr").html(bottomHtml);
            })
            $(".wordAdmin").on("click", ".rowBottom", function () {
                let myHtml = $(this).parents("tr").html();
                let bottomHtml = $(this).parents("tr").next().html();
                $(this).parents("tr").next().html(myHtml);
                $(this).parents("tr").html(bottomHtml);
            })
            $(".wordAdmin").on("click", ".addRow", function () {
                let $tr = $(this).parents("tr");
                $tr.after("<tr>" + $tr.html() + "</tr>");
                let td = $tr.next().find(".isList")
                for (let i = 0; i < td.length; i++) {
                    td[i].innerHTML = td[i].getAttribute("data-title");
                }
            })
            $(".wordAdmin").on("click", ".delRow", function () {
                $(this).parents("tr").remove();
            })
        },
        /**@method GPT缩放回调 */
        caleChange(caleType) {
            // caleType bigRight bigLeft centre small
            if (caleType == "bigRight") {
                $(".createWord").css("margin-left", "0px")
                $(".createWord").css("width", "calc(100% - 500px)")
            } else if (caleType == "bigLeft") {
                $(".createWord").css("margin-left", "500px")
                $(".createWord").css("width", "calc(100% - 500px)")
            } else if (caleType == 'centre') {
                $(".createWord").css("margin-left", "0px")
                $(".createWord").css("width", "100%")
            } else if (caleType == 'small') {
                $(".createWord").css("margin-left", "0px")
                $(".createWord").css("width", "100%")
            }
        },
        /**@method GPT点击回答回调 */
        clickAnswer(str) {
            if (!this.topic.text) {
                this.copyFun(str);
                return;
            }
            // $(`.${this.topic.classText}[data-title="${this.topic.text}"]`).text(str);
            let $wordContent = $(`.${this.topic.classText}[data-title="${this.topic.text}"]`)
            $wordContent.html(`<textarea class="wordTextarea">${str.replace(/<br>+/ig, "\n")}</textarea>`);
            $wordContent.children("textarea").focus();
            this.$nextTick(function () {
                $(".wordTextarea").css("height", $(".wordTextarea")[0].scrollHeight + 5 + "px");
            })
        },
        /**@method 关闭生成word模态框 */
        closeCreateWord() {
            this.showAddWord = false;
        },
        hideCreateWord() {
            this.$refs.gptModel.showHideGptModel(false);
            this.topic = {};
            // this.message = "";
        },
        /**@method 复制 */
        copyFun(text) {
            copyText(text)
            this.$message.success('复制成功！');
        },
        getObjectURL(file) {
            var url = null;
            if (window.createObjectURL != undefined) {
                url = window.createObjectURL(file);
            } else if (window.URL != undefined) {
                url = window.URL.createObjectURL(file);
            } else if (window.webkitURL != undefined) {
                url = window.webkitURL.createObjectURL(file);
            }
            return url;
        },
        addWordImage(file) {
            this.wordImageData.push({
                url: this.getObjectURL(file.raw),
                file: file.raw,
                tabText: "",
                width: 100,
                height: 100,
                leftOffset: "",
                topOffset: "",
            });
        },
        delWordImage(i) {
            this.wordImageData.splice(i, 1);
        },
        /**@method 显示选择公司 */
        showCompanyList() {
            this.$refs.selectCompanyList.showModelFun();
        },
        /**@method 选择公司 */
        selectCompany(row) {
            this.checkedCompany = row;
            sessionStorage.setItem("checkedProjectCompany", JSON.stringify(row));
            this.searchData.name = "";
            this.searchFun();
        },
        /**@method 获取提交数据 */
        getParams() {
            let params = {};
            let normal = this.modelContent.normal;//文本内容
            let list = this.modelContent.list;//列表内容
            for (let name of normal) {
                if ($(`.isInput[name=${name}]>textarea`).length > 0) {
                    params[name] = $(`.isInput[name=${name}]>textarea`).val().replace(/<br>+/ig, "\n");
                } else {
                    params[name] = $(`.isInput[name=${name}]`).html().replace(/<br>+/ig, "\n");
                }
            }
            /**
             * 多个列表数据 list=[ { 列表名: ["列名"] } ]
             * 值 row = { 列表名: ["列名"] } 
             * 值 tableName = 列表名
             * 值 liName = 列名
             */
            for (let row of list) {
                for (let tableName in row) {
                    if (!params[tableName]) {
                        params[tableName] = [];
                    }
                    for (let liName of row[tableName]) {// ["姓名"]
                        let $listTextarea = $(`.isList[name=${tableName}_${liName}]`);
                        if (tableName === '结果分析') { console.log($listTextarea); }
                        let content = "";
                        for (let i = 0; i < $listTextarea.length; i++) {
                            content = $listTextarea[i].innerHTML;
                            if (!params[tableName][i]) {
                                params[tableName][i] = {};
                            }
                            params[tableName][i][tableName + "_" + liName] = content.replace(/<br>+/ig, "\n");
                        }

                    }

                }

            }
            this.param = params;
        },
        /**@method 生成文档 */
        addWord() {
            let photoData = [];
            let file = [];
            for (let row of this.wordImageData) {
                let obj = { ...row };
                file.push(obj.file);
                delete obj.file;
                delete obj.url;
                photoData.push({ ...obj });
            }
            this.addWordDataLoading = true;
            this.getParams();
            let params = {
                remark: this.remark,
                param: base64.encode(JSON.stringify(this.param)),
                modelId: this.checkedModel.modelId,
                pdId: this.checkedProject.pdId,
                userId: this.userInfo.userId,
                pcId: this.checkedCompany.pcId,
                fileName: this.checkedProjectName
            };
            if (photoData.length > 0) {
                params.photoData = base64.encode(JSON.stringify(photoData));
            }
            if (this.formType == 'update') {
                params.dataId = this.updateId;
            }
            // this.$message.info("生成中请稍后...");
            writeAndDownload(params, { photoList: file }).then(res => {
                this.addWordDataLoading = false;
                if (res.code === 200) {
                    // window.open(res.data);
                    this.currentPage = 1;
                    this.searchData.name = "";
                    this.showModel = false;
                    this.showAddWord = false;
                    this.getList();
                } else {
                    this.$message.error(res.message)
                }
            }).catch(err => {
                this.addWordDataLoading = false;
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        /**@method 生成内容 */
        generatedContent() {
            let modelFormat = {};
            if (JSON.stringify(this.checkedCompany) != "{}") {
                let companyWords = {
                    companyName: "公司名",
                    businessAddress: "办公地址",
                    introduce: "公司介绍",
                    legalName: "法人代表",
                    registerAddress: "注册地址",
                    startTime: "成立时间",
                }
                for (let key in companyWords) {
                    modelFormat[companyWords[key]] = this.checkedCompany[key] ? this.checkedCompany[key] : "";
                }
            }
            if (JSON.stringify(this.checkedProject) != "{}") {
                let checkedProject = this.checkedProject;
                checkedProject.startEndDay = checkedProject[startDay] + "~" + checkedProject[endDay];
                let normalKeywords = {//项目数据赋值参数
                    projectNo: "序号",
                    projectName: "项目名",
                    money: "预算",
                    startEndDay: "研发日期",
                    startDay: "开始时间",
                    endDay: "结束时间",
                    headName: "负责人",
                    proposerName: "申请人",
                    testName: "测试人",
                    projectDay: "立项日期",
                    moneyFrom: "经费来源",
                    departmentName: "所属部门",
                    projectFrom: "项目来源",
                }
                for (let key in normalKeywords) {
                    modelFormat[normalKeywords[key]] = checkedProject[key] ? checkedProject[key] : "";
                }
                let listColKeywords = {
                    userName: "姓名",
                    department: "部门",
                    work: "岗位",
                    education: "学历",
                    university: "毕业院校",
                    major: "专业",
                    job: "职称",
                    inTime: "入职时间",
                    outTime: "离职时间",
                    remark: "备注",
                };
                let projectUserList = [];
                if (checkedProject.projectUserList) {
                    for (let row of checkedProject.projectUserList) {
                        let obj = {};
                        for (let key in listColKeywords) {
                            obj['项目组成员_' + listColKeywords[key]] = row[key] ? row[key] : "";
                        }
                        projectUserList.push(obj);
                    }
                }
                modelFormat['项目组成员'] = projectUserList;

            }
            if (JSON.stringify(this.checkedWord) != "{}") {
                let content = JSON.parse(this.checkedWord.content);
                for (let key in content) {
                    modelFormat[key] = content[key] ? content[key] : "";
                }
            }
            if (JSON.stringify(this.checkedModel) != "{}") {
                this.showContent = true;
                let htmlContent = JSON.parse(this.checkedModel.content);
                this.createWordHtml(htmlContent, modelFormat);
            }
        },
        showModelFun() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    this.showModel = true;
                    this.$refs.gptModel.showHideGptModel(true);
                    this.generatedContent();
                }
            })
        },
        showUpdateModel(row) {
            let params = {
                dataId: row.dataId,
                modelType: row.modelType
            };
            this.$refs.projectDevelop.showModel(true);
            this.modelLoading = true;
            getOneNormalWordData(params).then(res => {
                this.modelLoading = false;
                if (res.data) {
                    this.detailsProject = res.projectData;
                    this.detailsModel = res.wordData;
                }
            }).catch(err => {
                this.modelLoading = false;
            });
        },
        /**@method 显示编辑文档 */
        showSetModel(row) {
            this.updateId = row.dataId;
            this.formType = "update"
            this.showAddWord = true;
            this.checkedProjectName = row.projectName;
            this.checkedModelName = row.modelName;
            this.checkedProject = {
                projectName: row.projectName,
                pdId: row.pdId,
            }
            this.checkedWord = {}
            this.modelContent = {
                html: "",
                normal: [],
                list: []
            };
            this.formData = {
                modelId: row.modelId,
                pdId: row.pdId
            };
            this.wordImageData = [];
            this.remark = row.remark ? row.remark : "";
            // this.activeNames = ["1"];
            getWordModelList({
                modelId: row.modelId
            }).then(res => {
                this.checkedModel = res.data.pageInfo.list[0];
                this.loadingModel = false;
                let htmlContent = JSON.parse(res.data.pageInfo.list[0].content);//模板数据
                if (res.data) {
                    this.createWordHtml(htmlContent, row.modelFormat, 'readonly');
                } else {
                    this.$message.error(res.message)
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
                this.loadingModel = false;
            })
        },
        /**@method 显示生成文档 */
        showAddWordFun() {
            this.formType = "add"
            this.showAddWord = true;
            this.checkedProjectName = "选择研发项目";
            this.checkedModelName = "选择Word模板";
            this.checkedProject = {}
            this.checkedModel = {}
            this.checkedWord = {}
            this.modelContent = {
                html: "",
                normal: [],
                list: []
            };
            this.formData = {
                modelId: "",
                pdId: ""
            };
            this.wordImageData = [];
            this.remark = "";
            // this.activeNames = ["1"];
        },
        /**@method 显示文档数据 */
        showWordTable() {
            this.$refs.wordTable.showModelFun();
        },
        /**@method 选中文档数据 */
        selectWord(data) {
            this.checkedWord = data;
            this.$refs.wordTable.hideModelFun();
            this.checkedWord = {};
        },
        /**@method 显示选择模板 */
        showModelTable() {
            this.$refs.modelTable.showModelFun();
        },
        /**@method 选中模板 */
        selectModel(data) {
            this.checkedModelName = data.name;
            this.checkedModel = data;
            this.formData.modelId = data.modelId;
            this.$refs.modelTable.hideModelFun();
            if (JSON.stringify(this.checkedModel) != "{}" && JSON.stringify(this.checkedProject) != "{}") {
                this.showModelFun();
            }
        },
        /**@method 显示选择项目 */
        showProjectTable() {
            this.$refs.projectTable.showModelFun();
        },
        /**@method 选中项目 */
        selectProject(data) {
            this.checkedProjectName = data.projectName;
            this.checkedProject = data;
            this.formData.pdId = data.pdId;
            this.$refs.projectTable.hideModelFun();
            if (JSON.stringify(this.checkedModel) != "{}" && JSON.stringify(this.checkedProject) != "{}") {
                this.showModelFun();
            }
        },
        /**@method 删除文档 */
        delWordData(row) {
            this.$confirm("确定要继续删除这条文档数据", "删除文档", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                let params = {
                    dataId: row.dataId
                }
                delWordData(params).then(res => {
                    if (res.code === 200) {
                        this.$message.success('删除完成');
                        this.searchData.name = "";
                        this.searchFun();
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    if (err.code === 201) {
                        this.$message.error(err.message);
                    }
                })
            })
        },
        /**@method 删除文档 */
        delNormalWordData(row) {
            this.$confirm("确定要继续删除这条文档数据", "删除文档", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                let params = {
                    dataId: row.dataId,
                    modelType: row.modelType
                }
                delNormalWordData(params).then(res => {
                    if (res.code === 200) {
                        this.$message.success('删除完成');
                        this.searchData.name = "";
                        this.searchFun();
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    if (err.code === 201) {
                        this.$message.error(err.message);
                    }
                })
            })
        },
        createWordHtml(modelData, value, status = '') {
           //-console.log(modelData);
           //-console.log(value);
            let modelHtml = modelData.html;
            if (status === 'readonly') {
                modelHtml = modelHtml ? modelHtml.replace(/<textarea/ig, '<textarea ' + status) : "";
            }
            modelHtml = modelHtml.replace(/<table/ig, '<div class="modelTable"><table width="100%"')
            modelHtml = modelHtml.replace(/<\/table>/ig, '</table></div>')
            this.modelContent = {
                html: modelHtml,
                normal: modelData.normal ? modelData.normal : [],
                list: modelData.list ? modelData.list : []
            };
            this.$nextTick(() => {
                for (let key in value) {
                    let row = value[key];
                    /**判断数据类型，
                     * 为列表是时候赋值列表数据
                     * 为内容的时候赋值内容
                     */
                    if (typeof row === 'object') {
                        let tableTrView = null;
                        for (let childItem of row) {//循环列表
                            let tableTrHtml = "";
                            for (let childKey in childItem) {//循环列名
                                if (!tableTrView) {
                                    tableTrView = $(`.showModelContent .isList[name=${childKey}]`).parents('tr');
                                    if (tableTrView.length > 0) {//删除多余的列表行
                                        for (let i = 0; i < tableTrView.length; i++) {
                                            if (i != 0) {
                                                tableTrView[i].remove();
                                            }
                                        }
                                    }
                                }
                                // let tableTextarea = tableTrView.find(`textarea.isList[name=${childKey}]`)
                                // if (tableTextarea && tableTextarea.length > 0) {//文本框赋值
                                //     tableTextarea.html(childItem[childKey]);
                                // }
                                let textareaLists = tableTrView.find(`.isList`)//文本框赋值
                                for (let i = 0; i < textareaLists.length; i++) {
                                    let $html = $(textareaLists[i]);
                                    if ($html.attr("name").indexOf(childKey) != -1) {
                                        $html.html(childItem[childKey] ? childItem[childKey].replace(/\n+/ig, "<br>") : childKey);
                                    }
                                }
                            }
                            tableTrHtml = tableTrView.html();
                            tableTrView.before("<tr>" + tableTrHtml + "</tr>");//插入列表行
                        }
                        if (tableTrView) {
                            tableTrView.remove();
                        }
                    } else {
                        let textareaInputs = $(`.showModelContent .isInput`);
                        for (let i = 0; i < textareaInputs.length; i++) {
                            let $html = $(textareaInputs[i]);
                            if ($html.attr("name").indexOf(key) != -1) {
                                if (row) {
                                    $html.html(String(row).replace(/\n+/ig, "<br>"));
                                    $html.removeClass("wordButton");
                                }
                            }
                        }
                    }
                }
                let $textAreaIsList = $(".showModelContent .isList");
                if ($textAreaIsList && $textAreaIsList.length > 0 && status != 'readonly') {//添加 添加/删除按钮
                    let buttonHtml = `<td>
                        <div class="flex-a-c">
                            <div class="flex-a-c h30" style="width:85px;border:1px solid #51CDCB;padding:0 5px;position:absolute;border-radius:20px;margin-left:10px;background:#fff;">
                                <button type="button" class="el-button--text rowTop"><span class="el-icon-top"></span></button>
                                <button type="button" class="el-button--text rowBottom"><span class="el-icon-bottom"></span></button>
                                <button type="button" class="el-button--text addRow"><span class="el-icon-plus"></span></button>
                                <button type="button" class="el-button--text el-button--small delRow" style="color: rgb(245, 108, 108);"><span class="el-icon-delete"></span></button>    
                            </div>    
                        </div>
                    </td>`;
                    let $trView = $textAreaIsList.parents("tr");
                    let $addRow = $trView.find(".addRow");
                    if (!$addRow || $addRow.length == 0) {
                        $trView.append(buttonHtml);
                    }
                }
            })
        },
        /**@method 显示word格式 */
        showModelContent(data) {
            this.formType = "show"
            this.showModel = true;
            this.loadingModel = true;
            getWordModelList({
                modelId: data.modelId
            }).then(res => {
                this.loadingModel = false;
                let htmlContent = JSON.parse(res.data.pageInfo.list[0].content);//模板数据
                if (res.data) {
                    this.remark = data.remark;
                    this.createWordHtml(htmlContent, data.modelFormat);
                } else {
                    this.$message.error(res.message)
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
                this.loadingModel = false;
            })
        },
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                projectName: this.searchData.name
            };
            if (this.status != 'all' && this.status != '3') {
                params.status = this.status;
            }
            if (!this.checkedCompany.pcId) {
                this.tableData = [];
                this.total = 0
                return;
            } else {
                params.pcId = this.checkedCompany.pcId;
            }
            try {
                this.loading = true;
                let res = null;
                let tableData = [];
                if (this.status != 3) {
                    res = await getWordDataList(params)
                    this.loading = false;
                    const { data } = res;
                    for (let row of data.pageInfo.list) {
                        tableData.push({
                            ...row,
                            modelFormat: JSON.parse(row.content)
                        })
                    }
                } else {
                    res = await getNormalWordDataList(params)
                    this.loading = false;
                    const { data } = res;
                    tableData = data.pageInfo.list;
                }
                this.tableData = tableData;
                this.total = res.data.pageInfo.total;

            } catch (error) {
               //-console.log(error);
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
    },
};
</script>
<style>
.wordTextarea {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #DCDFE6;
    text-align: justify;
}

.modelTable .wordTextarea {
    width: 100%;
}

.uploadWordImage {
    height: 36px;
}

/* 
.uploadWordImage .el-upload-list.el-upload-list--text {
    display: none;
} */
</style>
<style lang="scss" scoped>
.createWord {
    width: calc(100% - 500px);
}

.wordImageData {
    padding: 10px;
    border: 1px solid #EBEEF5;
    border-radius: 5px;
    width: calc(100% - 20px);
    position: relative;
    margin: 10px;

    .el-icon-close {
        border-radius: 50%;
        padding: 3px;
        border: 1px solid #999;
        position: absolute;
        top: -10px;
        right: -10px;
        background: #fff;
        cursor: pointer;
    }

    .wordImage {
        border-radius: 5px;
    }
}

.selectCompany {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 700;
    color: #51CBCD;
}

.uploadBtn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #3a78ef;
    color: #fff;
    font-size: 40px;
    position: fixed;
    top: 170px;
    cursor: pointer;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.50);
}

.closeBtn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #F56C6C;
    color: #fff;
    font-size: 40px;
    position: fixed;
    top: 230px;
    cursor: pointer;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.50);
}

.showModelContent {
    overflow-x: scroll;
    width: 842px;
    margin: auto;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    padding: 20px;
    margin-right: 100px;

}

/deep/ .el-upload--text {
    width: 90px;
    height: 36px;
    border: 0px;
}
</style>